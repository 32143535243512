<template>
  <div id="contact-us" class="lg:w-[1280px] lg:h-[578px] lg:mx-auto lg:flex lg:mt-10 lg:justify-between">
    <div class="lg:flex lg:flex-col lg:items-start lg:leading-[52px] lg:tracking-[-0.0045em]">
      <p v-if="title" class="lg:text-[36px] text-main-0 font-bold">{{ title }}</p>
      <p class="lg:text-[36px] text-text-0 font-bold">지금 바로 문의해 주세요.</p>
      <div class="lg:flex lg:flex-col lg:leading-loose lg:mt-2 lg:text-small-size font-medium text-gray">
        <p v-if="description" class="">{{ description }}</p>
        <p>평일 1일 내, 빠른 답변드립니다.</p>
        <p>전화 상담을 원하신다면 <span class="font-bold text-main-0">02-2085-0606</span> 으로 연락부탁드립니다.</p>
      </div>
    </div>
    <div class="">
      <form class="lg:flex lg:flex-col lg:w-[640px] lg:gap-6" @submit.prevent="submitInquiry"
        action="https://script.google.com/macros/s/AKfycbxkSLWI8SErbff6Pw5IQWLlnTyHhM4MyWhwGomlUA7TJ-VdJdVD7Zpzd3v_5Du538BE/exec">
        <div class="lg:flex lg:justify-between">
          <!--회사명-->
          <div class="lg:flex lg:w-[300px] lg:items-center lg:justify-between">
            <p class="text-text-0 lg:text-small-size">회사명<span class="text-[#FF5252]">&nbsp;*</span></p>
            <input id="companyName" v-model="form.companyName"
              class="lg:w-[216px] lg:h-[50px] pl-4 border-[1px] rounded-lg border-gray_3 focus:border-main-0 placeholder:text-small-size-1 outline-none"
              type="text" placeholder="회사명을 입력해주세요.">
          </div>
          <!--이름-->
          <div class="lg:flex lg:w-[300px] lg:items-center lg:justify-between">
            <p class="text-text-0 lg:text-small-size">이름<span class="text-[#FF5252]">&nbsp;*</span></p>
            <input
              class="lg:w-[216px] lg:h-[50px] pl-4 border-[1px] rounded-lg border-gray_3 focus:border-main-0 placeholder:text-small-size-1 outline-none"
              id="name" v-model="form.name" type="text" placeholder="이름을 입력해주세요.">
          </div>
        </div>

        <!-- 이메일 -->
        <div class="lg:flex lg:justify-between lg:items-center">
          <p class="text-text-0 lg:text-small-size">이메일<span class="text-[#FF5252]">&nbsp;*</span></p>
          <input id="email" v-model="form.email"
            class="lg:w-[556px] lg:h-[50px] pl-4 border-[1px] rounded-lg border-gray_3 focus:border-main-0 placeholder:text-small-size-1 outline-none"
            type="email" placeholder="이메일을 입력해주세요.">
        </div>

        <!-- 문의 플랫폼 -->
        <div v-if="isShowplatform" class="lg:flex lg:justify-between lg:items-center">
          <p class="text-text-0 lg:text-small-size">플랫폼<span class="text-[#FF5252]">&nbsp;*</span></p>
          <select id="platform" v-model="form.platform"
            class="lg:w-[556px] lg:h-[50px] pl-4 border-[1px] rounded-lg border-gray_3 focus:border-main-0 placeholder:text-small-size-1 outline-none">
            <option class="text-main-0" value="" disabled selected>선택해주세요</option>
            <option value="3">Q-Meet</option>
            <option value="1">Testing Service</option>
            <option value="2">Geteyes</option>
          </select>
        </div>

        <!-- 문의제목 -->
        <div class="lg:flex lg:justify-between lg:items-center">
          <p class="text-text-0 lg:text-small-size">문의제목<span class="text-[#FF5252]">&nbsp;*</span></p>
          <input id="title" v-model="form.title"
            class="lg:w-[556px] lg:h-[50px] pl-4 border-[1px] rounded-lg border-gray_3 focus:border-main-0 placeholder:text-small-size-1 outline-none"
            type="text" placeholder="문의하실 제목을 입력해주세요.">
        </div>

        <!-- 문의내용 -->
        <div class="lg:flex lg:justify-between lg:items-center">
          <p class="text-text-0 lg:text-small-size">문의내용<span class="text-[#FF5252]">&nbsp;*</span></p>
          <textarea id="question" v-model="form.question"
            class="lg:w-[556px] lg:h-28 pl-4 lg:py-3 border-[1px] rounded-lg border-gray_3 focus:border-main-0 placeholder:text-small-size-1 outline-none"
            type="text" placeholder="문의하실 내용을 입력해주세요."></textarea>
        </div>

        <!-- 휴대전화 -->
        <div class="lg:flex lg:justify-between lg:items-center">
          <p class="text-text-0 lg:text-small-size">휴대전화<span class="text-[#FF5252]">&nbsp;*</span></p>
          <input id="phone" v-model="form.phone"
            class="lg:w-[556px] lg:h-[50px] pl-4 border-[1px] rounded-lg border-gray_3 focus:border-main-0 placeholder:text-small-size-1 outline-none"
            type="tel" placeholder="휴대폰 번호를 입력해주세요.">
        </div>

        <!-- 개인정보 동의 -->
        <div class="lg:flex lg:justify-end">
          <input class="w-6 h-6 mr-4" type="checkbox" v-model="form.agreement">
          <p class="text-text-0 mr-2">(필수) 개인정보 제 3자 제공에 동의합니다.</p>
          <button class="text-gray text-small-size-1 underline decoration-gray"
            @click="goToPage('/terms')">상세보기</button>
        </div>

        <!-- 문의하기 -->
        <div id="contact-us" class="lg:flex lg:justify-end">
          <button class="w-40 h-9 bg-main-0 rounded">
            <p class="text-white text-small-size-1">문의하기</p>
          </button>
          <div
            :class="[isLoading ? 'ml-4 w-8 h-8 border-4 border-main-0 border-t-transparent rounded-full animate-spin' : '']">
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    qnaData: {
      type: Object,
      required: true,
    },
    isShowplatform: {
      type: Boolean,
      required: false,
    },
    title:{
      type: String,
    },
    description:{
      type: String,
    },
    propPlatform: {
      type: [String, Number], // 데이터 타입에 따라 수정
      required: false, // 필수가 아니라면 false      
    },
  },

  methods: {
    toggleSelection(item) {
      this.selectedItem = this.selectedItem === item ? null : item;
    },    
    submitInquiry() {
      
      if (!this.isFormValid) {
        console.log("submitInquiry 1");
        alert('필수 항목을 모두 입력해주세요.');
        return;
      }
      
      
      this.isLoading = true; // 로딩 시작
      // 폼 데이터를 URL 인코딩된 형태로 준비
      

      if(this.isShowplatform)
      {
        if(this.form.platform == '1')
            this.form.platform = "Testing Service";          
        else if(this.form.platform == '2')
          this.form.platform = "Geteyes";
        else if(this.form.platform == '3')
          this.form.platform = "Q-Meet";
      }
      else {
        if (this.propPlatform == '1') {
          this.form.platform = "Testing Service";
        }
        else if (this.propPlatform == '2') {
          this.form.platform = "Geteyes";
        }
        else if (this.propPlatform == '3') {
          this.form.platform = "Q-Meet";
        }
      }

      const formData = new URLSearchParams();
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key]);
      });

      // Google Apps Script URL
      const scriptURL = 'https://script.google.com/macros/s/AKfycbx_LvEF3n3reUw02TWIESjYXDehpnRNGwUivS0W0DyLI_KWOYsFd0F3Lci7EK98yO9M/exec';

      // 폼 데이터 전송
      fetch(scriptURL, {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      })
        .then(response => {
          console.log('Success!', response);
          this.formSubmitted = true;
          alert('빠른시일 내에 답변 드리도록 하겠습니다. 감사합니다.');
          this.resetForm();
        })
        .catch(error => {
          console.error('Error!', error.message);
        })
        .finally(() => {
          this.isLoading = false; // 로딩 종료
        });
    },
    resetForm() {
      this.form = {
        companyName: '',        
        email: '',
        phone: '',
        platform: '',
        question: '',
        title: '',
        agreement: false,
        name: ''
      };
    }

  },

  computed: {
    filteredList() {
      return this.questions.filter((q) => q.category === this.currentTab);
    },
    isFormValid() {
        console.log(this.form);
        console.log(this.form.companyName);
      if(!this.propPlatform)
        return this.form.companyName && this.form.email && this.form.phone && this.form.platform && this.form.title && this.form.question && this.form.agreement;
      else
        return this.form.companyName && this.form.email && this.form.phone && this.form.title && this.form.question && this.form.agreement;
    }
  },

  data() {
    return {
      isLoading: false, // 로딩 상태 추가
      form: {
        companyName: '',        
        name: '',
        email: '',
        platform: '',
        phone: '',
        question: '',
        title: '',
        agreement: false,
      },
      formSubmitted: false,
    }
  }
}
</script>

<style scoped>
.placeholder-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>