<template>
    <div
        class="lg:w-[630px] lg:h-[120px] lg:flex lg:flex-col lg:items-center lg:justify-center lg:rounded-2xl border-main-0 border-[1px]">
        <p class="lg:font-semibold lg:text-content-size text-main-1">{{ title }}</p>
        <p class="lg:text-small-size lg:mt-3 text-gray_1">{{ description }}</p>
    </div>
</template>
<script>
export default {
    props: ['title', 'description'],
    data() {
        return {

        }
    }
}
</script>
